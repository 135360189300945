import styled from 'styled-components';
import { spacing, dimensions, colors } from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.thirty};
`;

export const TileWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${dimensions.blogWrapperWidth};
  flex-wrap: wrap;
`;

export const ArticleSpacer = styled.div`
  width: 100%;
  background-color: ${colors.backgroundGrey};
  padding-top: 150px;
  padding-bottom: ${spacing.thirty};
`;

export const LowerWrapper = styled(ArticleSpacer)`
  background-color: ${colors.white};
  padding-top: ${spacing.thirty};
  min-height: 400px;
  display: flex;
  justify-content: center;
`;
