import { navigate } from 'gatsby';
import React from 'react';
import LoadableImage from '../../components/loadableImage';
import { convertDateToMonthDay } from '../../services/date';
import { PROMOTED_POST_ANCHOR } from '../../templates/blogIndex';
import { PrismicBlogTile } from '../blogArticle/model';
import { Content, DownButton, Subtitle, Title } from './styles';

export type BlogHeroProps = {
  data: PrismicBlogTile;
  uid: string;
  published: Date;
  category: string;
};

const BlogHero = ({ data, uid, published, category }: BlogHeroProps) => {
  const path = `blog/${uid}`;

  const onClick = () => {
    navigate(path);
  };

  return (
    <LoadableImage
      src={data.hero_image.url}
      isBackground
      height="80vh"
      mobileHeight="30vh"
      width="100%"
      onClick={onClick}
    >
      <Content to={path} id={PROMOTED_POST_ANCHOR}>
        <DownButton />
        <Title>{data.title.text}</Title>
        <Subtitle>
          <b>{category}</b> | {convertDateToMonthDay(published)}
        </Subtitle>
      </Content>
    </LoadableImage>
  );
};

export default BlogHero;
