import styled from 'styled-components';
import { colors, fontSizes, spacing } from '../../styles/theme';

export const Wrapper = styled.button<{ width?: string }>`
  height: 40px;
  width: ${props => (props.width ? `${props.width}` : `150px`)};
  display: flex;
  justify-content: center;
  font-size: ${fontSizes.buttonMobile};
  font-family: 'GilroySemiBold';
  border: 1px solid ${colors.borderGrey};
  margin-left: ${spacing.half};
  margin-right: ${spacing.half};
  color: ${colors.darkGrey};
  background-color: ${colors.white};
  background-image: linear-gradient(
    ${colors.brandYellow},
    ${colors.brandYellow}
  );
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.25s, color 0.25s;
  &:hover {
    background-size: 100% 100%;
    cursor: pointer;
  }
`;
