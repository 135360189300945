import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, fontSizes, spacing, media } from '../../styles/theme';
import UpButton from '../../assets/svgs/up-button.svg';

export const Content = styled(Link)`
  text-decoration: none;
  background-color: ${colors.white};
  max-height: 250px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: -90px;
  left: 25%;
  transform: translateX(-17%);
  max-width: 850px;
  padding: ${spacing.base};
  z-index: 1;
  ${media.desktop`
    bottom: -125px;
    padding: ${spacing.thirty};
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export const Title = styled.h3`
  font-size: ${fontSizes.subHeading};
  text-align: center;
  font-family: 'BressayBoldItalic';
  line-height: 110%;
  margin-top: ${spacing.base};
  ${media.desktop`
    font-size: ${fontSizes.large};
  `}
`;

export const Subtitle = styled.p`
  font-size: ${fontSizes.small};
  ${media.desktop`
    font-size: ${fontSizes.normal};
  `}
`;

export const DownButton = styled(UpButton)`
  display: none;
  transform: scale(-1, -1);
  position: absolute;
  top: -35px;
  ${media.desktop`
    display: block
  `}
`;
