import React from 'react';
import { Wrapper } from './styles';

export type ButtonGhostProps = {
  title: string;
  onClick: () => void;
  width?: string;
};

const ButtonGhost = ({ title, onClick, width }: ButtonGhostProps) => {
  return (
    <Wrapper onClick={onClick} width={width}>
      {title}
    </Wrapper>
  );
};

export default ButtonGhost;
