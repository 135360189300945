import styled from 'styled-components';
import { colors, fontSizes, spacing } from '../../styles/theme';
import { Link } from 'gatsby';

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const CategoryLink = styled(Link)`
  text-decoration: none;
`;

export const CategoryButton = styled.button<{
  isPressed: boolean;
}>`
  height: 40px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  font-size: ${fontSizes.buttonMobile};
  font-family: 'GilroySemiBold';
  border: 1px solid ${colors.borderGrey};
  margin-left: ${spacing.half};
  margin-right: ${spacing.half};
  color: ${colors.darkGrey};
  background-color: ${props =>
    props.isPressed ? colors.brandYellow : colors.white};
  background-image: linear-gradient(
    ${colors.brandYellow},
    ${colors.brandYellow}
  );
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.25s, color 0.25s;
  text-decoration: none;
  &:hover {
    background-size: 100% 100%;
    cursor: pointer;
  }
`;
