import slugify from '@sindresorhus/slugify';
import React from 'react';
import { categoryOptions } from '../../const/categoryOptions';
import { PROMOTED_POST_ANCHOR } from '../../templates/blogIndex';
import { CategoryButton, CategoryLink, CategoryWrapper } from './styles';

export type CategoriesProps = {
  selectedCat?: string; // TODO
};

const Categories = ({ selectedCat }: CategoriesProps) => {
  return (
    <CategoryWrapper>
      {categoryOptions.map((cat) => {
        const isPressed = cat === selectedCat;
        const slug = slugify(cat);
        const path = isPressed
          ? `/blog#${PROMOTED_POST_ANCHOR}`
          : `/blog/${slug}#${PROMOTED_POST_ANCHOR}`;
        return (
          <CategoryLink key={cat} to={path} id={slug}>
            <CategoryButton isPressed={isPressed}>{cat}</CategoryButton>
          </CategoryLink>
        );
      })}
    </CategoryWrapper>
  );
};

export default Categories;
