import { graphql, navigate } from 'gatsby';
import React from 'react';
import BlogArticle from '../../atoms/blogArticle';
import BlogHero from '../../atoms/blogHero';
import ButtonGhost from '../../atoms/buttonGhost';
import Categories from '../../atoms/categories';
import Layout from '../../components/layout';
import { AllBlog } from '../../components/relatedArticles';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../const/metaData';
import { CatWrapper } from '../../styles/commonStyles';
import { ArticleSpacer, LowerWrapper, TileWrapper, Wrapper } from './styles';

export type BlogIndexContext = {
  limit: number;
  skip: number;
  tags: string[];
  tagSlugs: string[];
  numPages: number;
  currentPage: number;
};

export type BlogIndexProps = {
  data: AllBlog;
  pageContext: BlogIndexContext;
};

const BlogIndex = ({
  data: { allPrismicBlog },
  pageContext: { tags, tagSlugs, currentPage, numPages },
}: BlogIndexProps) => {
  const { edges } = allPrismicBlog;

  const numberOfPosts = edges.length;
  const nextPage = currentPage + 1;
  const isFilteredByTag = tags.length === 1;
  const currentTag = isFilteredByTag ? tags[0] : undefined;
  const currentTagSlug = isFilteredByTag ? tagSlugs[0] : undefined;

  const toNextPage = () => {
    if (currentPage === numPages) {
      return;
    }
    const path = isFilteredByTag
      ? `blog/${currentTagSlug}/${nextPage}#${PROMOTED_POST_ANCHOR}`
      : `blog/${nextPage}#${PROMOTED_POST_ANCHOR}`;
    navigate(path);
  };

  const latestPost = edges[0];
  const otherPosts = numberOfPosts > 1 ? edges.slice(1) : [];
  const showViewMore = currentPage < numPages;

  return (
    <Layout>
      <SEO title={titles.blog} description={descriptions.blog} />
      <Wrapper>
        <BlogHero
          data={latestPost.node.data}
          uid={latestPost.node.uid}
          published={latestPost.node.first_publication_date}
          category={latestPost.node.tags[0]}
        />
        <ArticleSpacer />
        <CatWrapper>
          <Categories selectedCat={currentTag} />
        </CatWrapper>
        {otherPosts.length > 0 && (
          <LowerWrapper>
            <TileWrapper>
              {otherPosts.map((post) => (
                <BlogArticle data={post} key={post.node.uid} />
              ))}
            </TileWrapper>
          </LowerWrapper>
        )}
        {showViewMore && (
          <ButtonGhost title="View more" onClick={toNextPage} width="300px" />
        )}
      </Wrapper>
    </Layout>
  );
};

export const blogIndexQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!, $tags: [String!]) {
    allPrismicBlog(
      filter: { tags: { in: $tags } }
      sort: { fields: first_publication_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          tags
          first_publication_date
          data {
            hero_image {
              url
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`;

/**
 * This is used to navigate the page down to the categories filter when the
 * user activates one.
 *
 * NB: We re-use the promoted post as the anchor so that the spacing is correct
 * to accommodate for the floating header.
 */
export const PROMOTED_POST_ANCHOR = 'categories';

export default BlogIndex;
